import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { runStmEstimate } from '../../store';
import HorizontalRule from '../atoms/HorizontalRule';
import CheckList from '../molecules/CheckList';
import { Anchor } from '~lib/frontend/atoms';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import SelectInput from '~lib/frontend/design-system/components/input/molecules/SelectInput';
import { SelectOption } from '~lib/frontend/design-system/components/input/types';
import Typography from '~lib/frontend/design-system/components/Typography';
import Footer from '~website/components/layout/footer';
import VibrantScreen from '~website/components/VibrantScreen';
import VibrantScreenCard from '~website/components/VibrantScreen/VibrantScreenCard';
import { trackQuoteEvent } from '~website/features/analytics/store/actions';
import NegativeFeedback from '~website/features/quote/components/molecules/NegativeFeedback';
import { monzoMoney } from '~website/helpers/monies';

const YourStmEstimate: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const stmEstimate = useSelector(s => s.internal.quote.estimate.stm);
	const { flow } = useSelector(s => s.internal.quote.state);
	const [selectedDuration, setSelectedDuration] = useState(0);
	const [durations, setDurations] = useState<SelectOption<number>[]>([]);

	useEffect(() => {
		if (!stmEstimate) {
			dispatch(runStmEstimate.actions.request());

			return;
		}

		if (!stmEstimate.response) return;

		const durs = stmEstimate.response.quotes.map(q => {
			const isHours = q.duration < 86400;
			const friendly = isHours ? q.duration / 60 / 60 : q.duration / 60 / 60 / 24;
			const singular = friendly === 1;

			return {
				label: `${friendly} ${isHours ? 'hour' : 'day'}${singular ? '' : 's'}`,
				value: q.duration,
			};
		}) as SelectOption<number>[];

		const dayQuote = durs.find(d => d.value === 86_400);

		if (flow && flow === 'daily' && dayQuote) setSelectedDuration(dayQuote.value);
		else setSelectedDuration(durs[0].value);

		setDurations(durs);
	}, [dispatch, flow, stmEstimate]);

	const quote = stmEstimate?.response?.quotes.find(q => q.duration === selectedDuration);
	const error = stmEstimate?.error;

	useEffect(() => {
		if (quote) dispatch(trackQuoteEvent({ action: 'stm_estimate_received' }));
	}, [dispatch, quote]);

	useEffect(() => {
		if (error) dispatch(trackQuoteEvent({ action: 'stm_estimate_failed' }));
	}, [dispatch, error]);

	return (
		<Fragment>
			<VibrantScreen title={"Here's your estimate"}>
				<VibrantScreenCard>
					<EstimateWrap>
						<CenteredCardPricing>
							{quote && (
								<Fragment>
									<Typography $type={'Heading.XLarge'} $color={'textOnSurfaceBackground'}>
										{monzoMoney(quote.totalPayable)}
									</Typography>
									<InputWrapper>
										<SelectInput
											value={selectedDuration}
											options={durations}
											onChange={({ value }) => {
												setSelectedDuration(value as number);
											}}
										/>
									</InputWrapper>
								</Fragment>
							)}
							{error && <NegativeFeedback error={error} />}
							{!quote && !error && (
								<Margin $marginTop={'large'} $marginBottom={'extraLarge'}>
									<Typography
										$type={'Body.XLarge'}
										$color={'textOnSurfaceBackgroundMuted'}
										$display={'block'}
									>
										{'Loading..'}
									</Typography>
								</Margin>
							)}
						</CenteredCardPricing>

						{quote && (
							<React.Fragment>
								<Button
									as={Anchor}
									// @ts-ignore
									to={'/quote/download'}
									$type={'primary'}
									$size={'large'}
									$stretch
									onClick={() =>
										dispatch(trackQuoteEvent({ action: 'stm_estimate_continue_selected' }))
									}
								>
									{'Continue'}
								</Button>

								<NewQuoteLinkContainer>
									<NewQuotesLink
										$type={'Label.Medium'}
										$color={'textHighlight'}
										$bold
										as={Anchor}
										to={'/vehicles'}
										$display={'block'}
										$marginTop={'24px'}
										$marginBottom={'24px'}
										$marginLeft={'auto'}
										$marginRight={'auto'}
										$align={'center'}
									>
										{'Start again'}
									</NewQuotesLink>
									<HorizontalRule />
								</NewQuoteLinkContainer>

								<Margin $marginTop={'extraLarge'}>
									<CheckList title={'About your cover'}>
										{'Fully comprehensive insurance'}
										{'Only pay for what you need'}
										{'Easy to extend in the app'}
									</CheckList>
								</Margin>
							</React.Fragment>
						)}
					</EstimateWrap>
				</VibrantScreenCard>
			</VibrantScreen>
			<Footer landingPage />
		</Fragment>
	);
};

const EstimateWrap = styled.div`
	max-width: 350px;
	margin: 0 auto;
`;

const NewQuotesLink = styled(Typography)`
	cursor: pointer;
`;

const NewQuoteLinkContainer = styled.div`
	a,
	a:hover,
	a:visited {
		text-decoration: none;
		cursor: pointer;
	}
`;

const CenteredCardPricing = styled.div`
	text-align: center;
`;

const InputWrapper = styled.div`
	margin: ${p => p.theme.spacing.extraLarge} 0;
`;

export default YourStmEstimate;
