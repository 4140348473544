import React from 'react';
import { createRoot } from 'react-dom/client';
import CuvvaClient, { LocalStore } from '@cuvva/cuvva-client';
import { createBrowserHistory } from 'history';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import configureStore from './configure-store';
import { APP_PREFIX } from './constants/storage';
import environmentSetup from './environment';
import { sourceTracking } from './features/analytics/store/actions';
import Main from './Main';
import { contentSegments } from './prerender/content';
import fetchContent from './prerender/fetchContent';
import { importRegisterUser } from './store/auth/actions';
import { COMMON_PREFIX } from '~lib/frontend/constants/storage';
import { getSegment } from '~lib/platform/content/store/actions';
import { LocalStorage } from '~lib/shared/helpers/local-storage';
import { setupLogger } from '~lib/shared/logger';

setupLogger('website');

const { baseUrl, clientId, redirectUri, hostEnvironment } = environmentSetup();
const history = createBrowserHistory();
const container = document.getElementById('cuvva-app-injection-site');

const client = new CuvvaClient({
	baseUrl,
	clientId,
	authStorage: new LocalStore(`${COMMON_PREFIX}.auth`),
	localStorage: new LocalStorage(`${APP_PREFIX}.auth`),
	redirectUri,
});

// If we're running locally, fetch the content then render
if (hostEnvironment === 'local') {
	(async () => {
		const content = await fetchContent(client, contentSegments);
		const store = configureStore(history, client, content);

		store.dispatch(importRegisterUser.request());
		store.dispatch(sourceTracking());

		const root = createRoot(container!); // createRoot(container!) if you use TypeScript

		root.render(<Main history={history} store={store} />);
	})();
} else {
	const store = configureStore(history, client);

	store.dispatch(importRegisterUser.request());
	contentSegments.map(segment =>
		store.dispatch(
			getSegment.request({
				schemaVersion: '1.0.0',
				segment,
			})
		)
	);
	store.dispatch(sourceTracking());

	const root = createRoot(container!); // createRoot(container!) if you use TypeScript

	root.render(<Main history={history} store={store} />);
}
