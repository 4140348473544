import React from 'react';
import { useSelector } from 'react-redux';

import YourLtmEstimate from './organisms/YourLtmEstimate';
import YourStmEstimate from './organisms/YourStmEstimate';

const LegacyQuotePage: React.FunctionComponent = () => {
	const { product } = useSelector(s => s.internal.quote.state);

	if (product === 'ltm') return <YourLtmEstimate />;

	return <YourStmEstimate />;
};

export default LegacyQuotePage;
