import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Cher from '@cuvva/cher';
import styled from 'styled-components';

import { LtmQuoteContext } from './quote-page/context';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import Footer from '~website/components/layout/footer';
import VibrantScreen from '~website/components/VibrantScreen';
import VibrantScreenCard from '~website/components/VibrantScreen/VibrantScreenCard';
import { trackQuoteEvent } from '~website/features/analytics/store/actions';
import NegativeFeedback from '~website/features/quote/components/molecules/NegativeFeedback';

const CannotQuote: React.FunctionComponent = () => {
	const history = useHistory<{ error: Cher | undefined }>();
	const routerError = history.location?.state?.error;
	const dispatch = useDispatch();
	const { quote } = useContext(LtmQuoteContext);
	const estimates = useSelector(s => s.internal.quote.estimate);
	const product = useSelector(s => s.internal.quote.state.product);

	const error = (() => {
		if (routerError) return routerError;

		if (product === 'ltm') return estimates.ltm?.error ?? quote.error;

		return estimates.stm?.error;
	})();

	// check if there's no error and redirect somewhere else.
	useEffect(() => {
		if (!error) history.push(createAwareUrl('/vehicles'));
	}, [history, error]);

	useEffect(() => {
		if (product) dispatch(trackQuoteEvent({ action: `${product}_quote_failed` }));
	}, [product, dispatch]);

	return (
		<React.Fragment>
			<VibrantScreen>
				<VibrantScreenCard>
					<CenteredCardPricing>{error && <NegativeFeedback error={error} />}</CenteredCardPricing>
				</VibrantScreenCard>
			</VibrantScreen>
			<Footer landingPage />
		</React.Fragment>
	);
};

const CenteredCardPricing = styled.div`
	text-align: center;
`;

export default CannotQuote;
